<template>
    <Template
        :headers="headers"
        @onAdd="() => (isShow = true)"
        :contacts="getContactList()"
        :pagination="{
            totalRecords: getTotalContacts(),
            currentPage,
            perPage,
            onChangePerPage: (count) => perPageChanged(count),
            onChangePage: (page) => pageChanged(page),
        }"
        @onImport="() => (isShowImport = true)"
        @onClickItem="
            (item) =>
                $router.push({
                    name: 'Venture Contact Detail',
                    params: { ventureId: this.venture?.id, id: item?.id },
                })
        "
        :venture="venture"
        :searchText="searchText"
        :onSearchTextChange="(text) => onSearchTextChange(text)"
        :isPageLoading="isPageLoading"
    />
    <WithNoSwiper
        v-bind="{
            isShow: isShow,
            isLoading: false,
            isSaving: isLoading,
            list: contactForm,
            modelKey: 'modelField',
            title: 'Add a new Contact',
            formData,
            content:
                'Contacts are real people that fit your target customer definition.',
        }"
        :saveForm="saveForm"
        @onClose="
            () => {
                isShow = false;
            }
        "
    />
    <ImportModal
        v-bind="{
            isShow: isShowImport,
            showCustomContent: true,
            isLoading: false,
            isSaving: isLoading,
            title: 'Import Contacts',
        }"
        @onClose="
            () => {
                isShowImport = false;
            }
        "
        :targetCustomerUsers="
            this?.venture?.targetCustomerUsers?.filter((t) => !t?.isArchived) ||
            []
        "
        @saveForm="saveForm"
    />
</template>

<script>
import Template from "../components/templates/VentureContacts/VentureContacts";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import ImportModal from "@/components/organisms/Modals/ImportModal/ImportModal";
import { contactForm } from "../../.storybook/sampleData";
import { convertEnumToRead } from "@/utils/utils.js";
import moment from "moment";
import { json } from "overmind";
import { upperFirst } from "lodash";

export default {
    components: {
        WithNoSwiper,
        Template,
        ImportModal,
    },
    data() {
        return {
            isShow: false,
            isShowImport: false,
            contactForm,
            isLoading: false,
            isLoadingImport: false,
            venture: null,
            contacts: [],
            formData: {},
            headers: [],
            perPage: 10,
            currentPage: 1,
            searchText: "",
            json,
            isPageLoading: false,
        };
    },
    methods: {
        getTotalContacts() {
            var contactList = json(this.contacts);
            if (this.searchText.trim())
                contactList = [...contactList].filter(
                    (item) =>
                        item["Full Name"]
                            ?.toLowerCase()
                            .includes(this.searchText.trim().toLowerCase()) ||
                        item["Contact Info"]
                            ?.toLowerCase()
                            .includes(this.searchText.trim().toLowerCase())
                );
            return contactList.length;
        },
        getContactList() {
            var contactList = json(this.contacts);
            if (this.searchText.trim())
                contactList = [...contactList].filter(
                    (item) =>
                        item["Full Name"]
                            ?.toLowerCase()
                            .includes(this.searchText.trim().toLowerCase()) ||
                        item["Contact Info"]
                            ?.toLowerCase()
                            .includes(this.searchText.trim().toLowerCase())
                );

            return json(contactList)?.splice(
                this.perPage * (this.currentPage - 1),
                this.perPage
            );
        },
        async saveForm(data, isMultipleContact) {
            if (this.isLoading) return false;
            this.isLoading = true;
            var contacts = isMultipleContact
                ? data?.contacts.filter((o) => o?.isArchived != true)
                    ? [...data?.contacts]
                    : []
                : [{ ...data }];
            var users = [];
            for (var i = 0; i < contacts.length; i++) {
                let user = null;
                if (contacts[i]?.email) {
                    const createAppUser = await this.actions.createAppUser({
                        email: contacts[i].email,
                        sendEmailVerification: false,
                    });
                    user = createAppUser?.user;
                    if (!user?.id) {
                        if (isMultipleContact) {
                            continue;
                        } else {
                            this.actions.alert.showError({
                                mesasge: "User already exists",
                            });
                            break;
                        }
                    }
                }
                const contactData = {};
                if (contacts[i]?.firstName) {
                    contactData["firstName"] = contacts[i].firstName;
                    contactData["username"] =
                        contacts[i].firstName?.toLowerCase();
                }
                if (contacts[i]?.lastName)
                    contactData["lastName"] = contacts[i].lastName;
                if (contacts[i]?.phone)
                    contactData["phones"] = {
                        create: { number: contacts[i].phone },
                    };
                const saveUser = await this.actions.user.saveUser({
                    where: user?.id ? { id: user.id } : null,
                    data: contactData,
                });
                users.push({ ...saveUser, ...contacts[i] });
            }
            console.log(users, "users");
            if (users.length > 0) {
                const contacts = [];

                users?.map((user) => {
                    const socialHandles = [];
                    if (isMultipleContact) {
                        if (user?.facebook)
                            socialHandles.push({
                                platform: "FACEBOOK",
                                handle: user?.facebook,
                            });
                        if (user?.twitter) {
                            socialHandles.push({
                                platform: "TWITTER",
                                handle: user?.twitter,
                            });
                        }
                        if (user?.instagram) {
                            socialHandles.push({
                                platform: "INSTAGRAM",
                                handle: user?.instagram,
                            });
                        }
                        if (user?.linkedin) {
                            socialHandles.push({
                                platform: "LINKEDIN",
                                handle: user?.linkedin,
                            });
                        }
                    } else {
                        if (data?.platform?.name && data?.socialLink) {
                            socialHandles.push({
                                platform: data?.platform?.name,
                                handle: data?.socialLink,
                            });
                            // socialHandles.push({
                            //     handle: data?.firstName,
                            //     platform: data?.platform?.name,
                            // });
                        } else if (data?.platform?.name && !data?.socialLink) {
                            socialHandles.push({
                                handle: data?.firstName,
                                platform: data?.platform?.name,
                            });
                        } else if (!data?.platform?.name && data?.socialLink) {
                        }
                    }
                    console.log(socialHandles, "socialHandles");
                    this.actions.user.saveUser({
                        where: { id: user?.id },
                        data: {
                            socialHandles: { create: socialHandles },
                        },
                    });
                    const metadata = [];
                    this.venture.targetCustomerUsers
                        .find((t) => t?.id === data?.targetCustomer?.id)
                        ?.emailTemplates?.map((e) =>
                            metadata.push({ id: e?.id, isSent: false })
                        );
                    contacts.push({
                        user: { connect: { id: user?.id } },
                        relationship: isMultipleContact
                            ? "STRANGER"
                            : data?.relationship?.value,
                        targetCustomerUser: {
                            connect: {
                                id: data?.targetCustomer?.id,
                            },
                        },
                        status: "NEW_CONTACT",
                        metadata,
                    });
                });

                const params = {
                    where: { id: this.$route.params?.ventureId },
                    data: {
                        contacts: {
                            create: contacts,
                        },
                    },
                };

                await this.actions.venture.saveVenture(params);
                const ventures = await this.actions.venture.getVentures({
                    where: { id: this.$route.params?.ventureId },
                    getValues: true,
                    query: "ventureContacts",
                });
                this.venture = ventures[0];
                this.onInitialize();
                this.actions.alert.showSuccess({
                    message: isMultipleContact
                        ? "Contacts imported successfully"
                        : "Created a contact successfully!",
                });
            }
            const venture = await this.actions.venture.getVentureById(
                this.$route.params?.ventureId
            );
            let surveyData = [];
            for (
                let i = 0;
                i <
                venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                    ?.length;
                i++
            ) {
                const targetCustomer = venture.targetCustomerUsers?.filter(
                    (t) => !t?.isArchived
                )[i];
                const data = await this.actions.userSurvey.getUserSurveys({
                    where: {
                        isArchived_not: true,
                        survey: {
                            targetCustomerUser: { id: targetCustomer?.id },
                        },
                    },
                    getValues: true,
                });
                console.log(data, "data");
                surveyData = [...surveyData, ...data];
            }
            venture.surveyData = surveyData;
            this.actions.setCurrentVenture(venture);
            this.isShow = false;
            this.isShowImport = false;
            this.isLoading = false;
        },
        showContactInfo(user) {
            let emails = "";
            user?.emails?.map((e) => (emails += `<div>${e.address}</div>`));
            if (user?.emails?.length > 0) {
                return `<div>${emails}</div>`;
            } else if (
                user?.socialHandles?.length > 0 &&
                user?.emails?.length === 0
            ) {
                let links = "";
                user?.socialHandles?.map(
                    (s) => (links += `<div>${s?.platform} - ${s?.handle}</div>`)
                );
                return links;
            } else if (user?.username) {
                return user?.username;
            } else {
                return "";
            }
        },
        perPageChanged(count) {
            this.perPage = count;
            this.currentPage = 1;
        },
        pageChanged(page) {
            this.currentPage = page;
        },
        onSearchTextChange(searchText) {
            this.currentPage = 1;
            this.searchText = searchText;
        },
        onInitialize() {
            this.headers = [
                { label: "Full Name", isSort: true, isAscending: true },
                { label: "Target Customer", isFilter: true },
                { label: "Contact Info", isSort: true, isAscending: true },
                { label: "Relationship", isFilter: true },
                { label: "Status", isFilter: true },
                { label: "Interview Date", isSort: true, isAscending: true },
            ];
            this.contacts = [];
            this.venture?.contacts
                ?.filter((o) => o?.isArchived != true)
                ?.map((p) => {
                    this.contacts.push({
                        "Full Name": `${p?.user?.firstName || ""} ${
                            p?.user?.lastName || ""
                        }`,
                        "Target Customer": upperFirst(
                            p?.targetCustomerUser?.name || ""
                        ),
                        "Contact Info": this.showContactInfo(p?.user),
                        Relationship: convertEnumToRead(p?.relationship),
                        Status: convertEnumToRead(p?.status),
                        "Interview Date": p?.interviews?.[0]?.scheduledDateTime
                            ? moment(
                                  p?.interviews?.[0]?.scheduledDateTime
                              ).format("M/DD @ h:mm")
                            : "",
                        id: p?.id,
                    });
                });
        },
    },
    async created() {
        this.isPageLoading = true;
        const ventures = await this.actions.venture.getVentures({
            where: { id: this.$route.params?.ventureId },
            getValues: true,
            query: "ventureContacts",
        });
        this.venture = ventures[0];
        this.contactForm[0].fields[5].options = JSON.stringify(
            this.venture.targetCustomerUsers?.filter((t) => !t?.isArchived) ||
                []
        );
        if (
            this.venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                ?.length === 1
        ) {
            this.formData.targetCustomer =
                this.venture.targetCustomerUsers?.filter(
                    (t) => !t?.isArchived
                )[0];
        }
        this.onInitialize();
        this.isPageLoading = false;
    },
};
</script>

<style scoped></style>
